import logo from "../svg/text-logo.svg"
import { useHistory } from "react-router-dom"
import { FC, useCallback, useState } from "react"
import { Translated } from "../utils/translated"
import { Button, Input, message } from "antd"
import { FlexCol } from "../components/commons-ts/common"
import { Label } from "../components/commons-ts/input"
import { callCloudApiV2, setLocalStorageCloudApiUser } from "../utils/cloudApiV2"
import { ICloudUser } from "../types"

export const LoginPage: FC = () => {
  const [antdMessageCtx, antdMessageCtxHolder] = message.useMessage()
  const history = useHistory()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      let { resp, entity: user } = await callCloudApiV2<ICloudUser>("/login", {
        method: "POST",
        body: new URLSearchParams({
          username,
          password,
        }),
      })
      if (resp.ok && user !== null) {
        setLocalStorageCloudApiUser(user)
        antdMessageCtx.success(`Welcome ${user.Username}!`)
        setTimeout(() => {
          history.push("/")
        }, 500)
      } else {
        antdMessageCtx.error(`Login failed!`)
      }
    } catch (e: any) {
      console.error(`Login: error: `, e)
      antdMessageCtx.error(`Error logging in: ${e.message}`)
    } finally {
      setIsLoading(false)
    }
  }, [username, password])

  return (
    <>
      {antdMessageCtxHolder}
      <FlexCol
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
          backgroundImage: "url(./accueil-01.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "50% auto",
          backgroundAttachment: "fixed",
          backgroundPosition: "right -20% bottom -20%",
        }}
      >
        <FlexCol
          style={{
            width: "100%",
            padding: "1rem",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            style={{
              marginBottom: "1rem",
            }}
            alt="logo"
          />
          <div className="paper login-form-container">
            <FlexCol
              style={{
                gap: 20,
              }}
            >
              <FlexCol
                style={{
                  gap: 0,
                }}
              >
                <Label>
                  <Translated keyEn="Username" />
                </Label>
                <Input
                  type="text"
                  name="username"
                  placeholder="Lug"
                  value={username}
                  onChange={(ev) => setUsername(ev.currentTarget.value)}
                  required
                  autoFocus
                  variant="filled"
                  size="large"
                />
              </FlexCol>
              <FlexCol
                style={{
                  gap: 0,
                }}
              >
                <Label>
                  <Translated keyEn="Password" />
                </Label>
                <Input
                  type="password"
                  name="password"
                  placeholder="*********"
                  onChange={(ev) => setPassword(ev.currentTarget.value)}
                  value={password}
                  required
                  variant="filled"
                  size="large"
                />
              </FlexCol>
              <FlexCol
                style={{
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button onClick={onSubmit} loading={isLoading} size="large" type="primary" block>
                  <Translated keyEn="Sign in" />
                </Button>
              </FlexCol>
            </FlexCol>
          </div>
        </FlexCol>
      </FlexCol>
    </>
  )
}
