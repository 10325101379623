import { FC, ReactElement, useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Draggable from "react-draggable"
import { ClipLoader } from "react-spinners"

import { Translated } from "../../utils/translated"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

interface ControlledPosition {
  x: number
  y: number
}

const Label: FC<{
  htmlFor: string
  children: ReactElement
}> = ({ children, htmlFor }) => {
  return (
    <label
      className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
      htmlFor={htmlFor}
    >
      {children}
    </label>
  )
}

export const SliderButton: FC<{
  isLoading: boolean
  resetTrigger: number
  onReleaseHIC: () => void
  heightTarget?: number
}> = ({ heightTarget, isLoading, resetTrigger, onReleaseHIC }) => {
  const sliderRail = useRef<HTMLDivElement>(null)
  const sliderButton = useRef<HTMLDivElement>(null)
  const [controlledPosition, setControlledPosition] = useState<ControlledPosition>({ x: 0, y: 0 })
  useEffect(() => {
    setControlledPosition({ x: 0, y: 0 })
  }, [resetTrigger])

  const onControlledDrag = (e: any, position: ControlledPosition) => {
    setControlledPosition(position)
  }

  const onControlledDragStop = (e: any, position: ControlledPosition) => {
    onControlledDrag(e, position)
    if (sliderButton.current === null || sliderRail.current === null) {
      return
    }
    const railWidth = sliderRail.current.offsetWidth
    const btnWidth = sliderButton.current.offsetWidth
    if (railWidth - btnWidth <= position.x + 12) {
      onReleaseHIC()
    } else {
      setControlledPosition({ x: 0, y: 0 })
    }
  }

  return (
    <>
      <div className="flex items-baseline justify-between">
        <Label htmlFor="newImpact">
          <Translated keyEn="new impact" />
        </Label>
        {heightTarget && (
          <div className="shadow-inner block tracking-wide py-2 rounded-full bg-yellow-light text-yellow-darker text-xs font-bold">
            <Translated keyEn={"height target"} />: {`${heightTarget.toFixed(2)}m`}
          </div>
        )}
      </div>
      <div
        id="lockCover"
        className={`w-full ${isLoading ? "select-none pointer-events-none cursor-not-allowed" : ""}`}
      >
        <div id="sliderCon">
          <div
            id="sliderRail"
            className={`uppercase bg-primary-light text-primary-lightest`}
            ref={sliderRail}
          >
            <p className="justify-end md:justify-center mr-8 md:mr-0 text-center font-bold text-2xl md:text-3xl flex items-center h-full leading-none">
              <Translated keyEn="slide to release" />
            </p>

            <Draggable
              axis="x"
              handle=".handle"
              bounds="parent"
              defaultPosition={{ x: 0, y: 0 }}
              position={controlledPosition}
              onStop={onControlledDragStop}
            >
              <div
                id="sliderButton"
                className="flex justify-center items-center handle bg-primary-lightest"
                ref={sliderButton}
              >
                {isLoading ? (
                  <ClipLoader sizeUnit={"px"} size={42} color={"#1a74ff"} loading={isLoading} />
                ) : (
                  <FontAwesomeIcon icon={faArrowRight} size="1x" color="#0065ff" />
                )}
              </div>
            </Draggable>
          </div>
        </div>
      </div>
    </>
  )
}
