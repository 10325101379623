import { DeleteFilled, DoubleRightOutlined } from "@ant-design/icons"
import { Tooltip, Button, Table, Input, Popconfirm, message } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Translated } from "../../utils/translated"
import { COLOR_BG_ROW_DELETED, pbUUIDToUuid } from "../../utils/utils"
import { Label } from "../../components/commons-ts/input"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { Impact } from "../../generated/proto-ts/main"
import { SyntheticImpactWithEmbeddedReferences } from "../../usercomm/common/usercommAsyncRequestGeneric"
import { useUsercommDeleteImpactBimodal } from "../../usercomm/common/usercommAsyncRequestBimodal"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"
import { ECloudRole } from "../../types"

export const SummaryImpactsTable: FC<{
  impacts: SyntheticImpactWithEmbeddedReferences[] | null
}> = ({ impacts }) => {
  const [antdMessageCtx, antdMessageCtxHolder] = message.useMessage()
  const [filteredImpacts, setFilteredSites] = useState<
    SyntheticImpactWithEmbeddedReferences[] | null
  >(null)
  const [searchValue, setSearchValue] = useState<string>("")
  const [deletedImpactAck, deleteImpact] = useUsercommDeleteImpactBimodal()
  const { currentUser } = useSyncronizationContext()

  useEffect(() => {
    if (impacts === null) {
      setFilteredSites(null)
      return
    }
    if (searchValue === "") {
      setFilteredSites(impacts)
      return
    }

    // TODO: debounce properly
    const t = setTimeout(() => {
      let _filteredSites: SyntheticImpactWithEmbeddedReferences[] = []
      let normalizedSearchValue = searchValue.toLowerCase().normalize()
      for (let impact of impacts) {
        if (impact.site !== null) {
          if (impact.site.mission_name.toLowerCase().normalize().includes(normalizedSearchValue)) {
            _filteredSites.push(impact)
            continue
          }
          if (impact.site.client_name.toLowerCase().normalize().includes(normalizedSearchValue)) {
            _filteredSites.push(impact)
            continue
          }
          if (impact.site.site_name.toLowerCase().normalize().includes(normalizedSearchValue)) {
            _filteredSites.push(impact)
            continue
          }
        }
        if (impact.equipment !== null) {
          if (
            impact.equipment.equipment_name
              .toLowerCase()
              .normalize()
              .includes(normalizedSearchValue)
          ) {
            _filteredSites.push(impact)
            continue
          }
        }
        if (impact.zone !== null) {
          if (impact.zone.zone_name.toLowerCase().normalize().includes(normalizedSearchValue)) {
            _filteredSites.push(impact)
            continue
          }
        }
      }
      setFilteredSites(_filteredSites)
    }, 250)
    return () => {
      clearTimeout(t)
    }
  }, [searchValue, impacts])

  const onDeleteImpact = useCallback(async (impact: Impact | null) => {
    if (impact === null) {
      return
    }
    deleteImpact(impact.uuid)
  }, [])
  useEffect(() => {
    if (deletedImpactAck === null) {
      return
    }
    antdMessageCtx.success("Impact deleted!")
  }, [deletedImpactAck])

  const columns: ColumnsType<SyntheticImpactWithEmbeddedReferences> = useMemo(() => {
    return [
      {
        key: "timestamp",
        title: <Translated keyEn="Date" />,
        render: (_, impact) => {
          if (impact.impact.created_at === 0) {
            return null
          }
          return new Date(impact.impact.created_at).toLocaleString()
        },
        sorter: (a, b) => a.impact.created_at - b.impact.created_at,
        defaultSortOrder: "descend",
      },
      {
        key: "site",
        title: <Translated keyEn="Site" />,
        render: (_, impact) => {
          if (impact.site === null) {
            return null
          }
          return (
            <Link to={`/sites/${pbUUIDToUuid(impact.site.uuid)}`}>{impact.site.site_name}</Link>
          )
        },
        sorter: (a, b) => {
          if (a.site === null || b.site === null) {
            return 0
          }
          return a.site.site_name.localeCompare(b.site.site_name)
        },
      },
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        render: (_, impact) => {
          if (impact.equipment === null) {
            return null
          }
          return (
            <Link to={`/equipments/${pbUUIDToUuid(impact.equipment.uuid)}`}>
              {impact.equipment.equipment_name}
            </Link>
          )
        },
        sorter: (a, b) => {
          if (a.equipment === null || b.equipment === null) {
            return 0
          }
          return a.equipment.equipment_name.localeCompare(b.equipment.equipment_name)
        },
      },
      {
        key: "zone",
        title: <Translated keyEn="Zone" />,
        render: (_, impact) => {
          if (impact.zone === null) {
            return null
          }
          return (
            <Link to={`/zones/${pbUUIDToUuid(impact.zone.uuid)}`}>{impact.zone.zone_name}</Link>
          )
        },
        sorter: (a, b) => {
          if (a.zone === null || b.zone === null) {
            return 0
          }
          return a.zone.zone_name.localeCompare(b.zone.zone_name)
        },
      },
      {
        key: "height",
        title: (
          <Tooltip overlay={<Translated keyEn="Measured height" />}>
            <Translated keyEn="Height" />
          </Tooltip>
        ),
        render: (_, impact) => {
          return <>{impact.impact.impact_ffh.toFixed(2)} m</>
        },
        sorter: (a, b) => a.impact.impact_ffh - b.impact.impact_ffh,
      },
      {
        key: "hic",
        title: (
          <Tooltip overlay={<Translated keyEn="HIC" />}>
            <Translated keyEn="HIC" />
          </Tooltip>
        ),
        render: (_, impact) => {
          return <>{impact.impact.impact_hic.toFixed(2)}</>
        },
        sorter: (a, b) => a.impact.impact_hic - b.impact.impact_hic,
      },
      {
        key: "gmax",
        title: (
          <Tooltip overlay={<Translated keyEn="Gmax" />}>
            <Translated keyEn="Gmax" />
          </Tooltip>
        ),
        render: (_, impact) => {
          return <>{impact.impact.impact_gmax.toFixed(2)}</>
        },
        sorter: (a, b) => a.impact.impact_gmax - b.impact.impact_gmax,
      },
      {
        key: "actions",
        title: "",
        width: "2rem",
        render: (_, impact) => {
          return (
            <FlexRow
              style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 0,
              }}
            >
              <Link to={`/impacts/${pbUUIDToUuid(impact.impact.uuid)}`}>
                <Button type="link" size="small">
                  <DoubleRightOutlined />
                </Button>
              </Link>
              {/* Delete */}
              <Tooltip overlay={<Translated keyEn="Delete" />}>
                <Popconfirm
                  title="Are you sure to delete this impact?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    onDeleteImpact(impact.impact)
                  }}
                >
                  <Button danger icon={<DeleteFilled />} type="text" />
                </Popconfirm>
              </Tooltip>
            </FlexRow>
          )
        },
      },
    ]
  }, [])

  return (
    <FlexCol>
      {antdMessageCtxHolder}
      <FlexCol style={{ gap: 0 }}>
        <Label>
          <Translated keyEn={"Universal search"} />
        </Label>
        <Input
          size="large"
          value={searchValue ?? ""}
          placeholder={"Search for mission, site, client, equipment, zone..."}
          onChange={(value) => {
            setSearchValue(value.target.value)
          }}
          variant="filled"
        />
      </FlexCol>
      <Table
        dataSource={filteredImpacts ?? []}
        loading={impacts === null}
        columns={columns}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
        }}
        rowKey={(impact) => pbUUIDToUuid(impact.impact.uuid)} // impact.uuid
        size="small"
        scroll={{ x: true }}
        bordered
        style={{
          width: "100%",
        }}
        onRow={(impact) => {
          return {
            style: {
              backgroundColor: impact.impact.deleted_at !== 0 ? COLOR_BG_ROW_DELETED : undefined,
            },
          }
        }}
      />
    </FlexCol>
  )
}
