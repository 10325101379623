import { DoubleRightOutlined } from "@ant-design/icons"
import { Tag, Tooltip, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { EResultTagAdequacy } from "../../../components/commons-ts/common"
import { Equipment, UUID } from "../../../generated/proto-ts/main"
import { useUsercommSiteChildrenRecursiveBLE } from "../../../usercomm/local/ble/usercommAsyncRequestBLE"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import { getEquipmentResultSM } from "../../../calculus/calculus_SM"
import { IEquipmentResult } from "../../../calculus/types"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { useUsercommSiteChildrenRecursiveBimodal } from "../../../usercomm/common/usercommAsyncRequestBimodal"

export const SiteEquipmentsTableSM: FC<{
  siteUUID: UUID | null
  equipments: Equipment[] | null
}> = ({ siteUUID, equipments }) => {
  const [equipmentResultsMap, setEquipmentResultsMap] = useState<Record<
    string,
    IEquipmentResult
  > | null>(null)
  const [_, equipmentZonesMap, zoneImpactsMap, getEntitiesRecursive] =
    useUsercommSiteChildrenRecursiveBimodal()

  useEffect(() => {
    if (siteUUID === null) {
      return
    }
    getEntitiesRecursive(siteUUID)
  }, [siteUUID])

  useEffect(() => {
    if (equipments === null || equipmentZonesMap === null || zoneImpactsMap === null) {
      return
    }
    let _equipmentResultsMap: Record<string, IEquipmentResult> = {}
    for (let equipment of equipments) {
      let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
      let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
      if (equipmentZones === undefined) {
        continue
      }
      let result = getEquipmentResultSM(equipment, equipmentZones, zoneImpactsMap)
      if (result === null) {
        continue
      }
      _equipmentResultsMap[equipmentUUIDStr] = result
    }
    setEquipmentResultsMap(_equipmentResultsMap)
  }, [equipmentZonesMap, zoneImpactsMap])

  const columns = useMemo((): ColumnsType<Equipment> => {
    return [
      {
        title: "📷", // Photo
        render: (_, equipment) => {
          let pictureUUID = equipment.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}>
              <TableImageThumbnail pictureUUID={pictureUUID} alt={equipment.equipment_name} />
            </Link>
          )
        },
      },
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        ellipsis: true,
        render: (_, equipment) => {
          return (
            <Link
              to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {equipment.equipment_name}
            </Link>
          )
        },
      },
      {
        title: <Translated keyEn="Mat type" />,
        render: (_, equipment) => {
          if (equipment.sports_mat_type === null) {
            return <span>N/A</span>
          }
          return <span>Type {equipment.sports_mat_type}</span>
        },
      },
      {
        title: <Translated keyEn="# Zones" />,
        render: (_, equipment) => {
          if (equipmentZonesMap === null) {
            return null
          }

          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
          if (equipmentZones == undefined) {
            return <Tag>N/A</Tag>
          }
          let nbZones = 0
          for (let zone of equipmentZones) {
            if (zone.deleted_at === 0) {
              nbZones++
            }
          }
          return <span>{nbZones}</span>
        },
      },
      {
        title: <Translated keyEn="# Impacts" />,
        render: (_, equipment) => {
          if (equipmentZonesMap === null || zoneImpactsMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
          if (equipmentZones == undefined) {
            return <Tag>N/A</Tag>
          }
          let nbImpacts = 0
          for (let zone of equipmentZones) {
            if (zone.deleted_at === 0) {
              let zoneImpacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
              if (zoneImpacts === undefined) {
                continue
              }
              for (let impact of zoneImpacts) {
                if (impact.deleted_at === 0) {
                  nbImpacts++
                }
              }
            }
          }
          return <span>{nbImpacts}</span>
        },
      },
      {
        key: "result",
        title: (
          <Tooltip overlay={<Translated keyEn="Result" />}>
            <Translated keyEn="Result" />
          </Tooltip>
        ),
        render: (_, equipment) => {
          if (equipmentResultsMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let result = equipmentResultsMap[equipmentUUIDStr]
          if (result === undefined || result === null) {
            return <Tag>N/A</Tag>
          }
          return <EResultTagAdequacy result={result.result} />
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, equipment) => {
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}>
              <Button type="link" size="small">
                <DoubleRightOutlined />
              </Button>
            </Link>
          )
        },
      },
    ]
  }, [equipments, equipmentZonesMap, zoneImpactsMap, equipmentResultsMap])

  return (
    <Table
      dataSource={equipments ?? []}
      loading={equipmentResultsMap === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      onRow={(equipment) => {
        return {
          style: {
            backgroundColor: equipment.deleted_at !== 0 ? COLOR_BG_ROW_DELETED : undefined,
          },
        }
      }}
      footer={() => <Translated keyEn="Site Equipments Table for EN 12503 - SPORTS MATS" />}
    />
  )
}
