import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { FlexCol, FlexRow, UnderlinedSectionTitle } from "../../components/commons-ts/common"
import { Avatar, Button, Col, Input, Row, Select, Switch, Tooltip, Typography } from "antd"
import { Translated } from "../../utils/translated"
import { Label } from "../../components/commons-ts/input"
import { IdcardOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import {
  loadLocalStorageDefaultSiteMethodType,
  saveLocalStorageDefaultSiteMethodType,
} from "../../utils/localStorage"
import { COLOR_BG_GREEN, COLOR_BG_ORANGE } from "../../utils/utils"
import { useTranslationContext } from "../../providers/translationProvider"
import { MethodTypeEN1177 } from "../../generated/proto-ts/main"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"
import { UploadUserImage } from "../../components/commons-ts/uploadUserImage"
import { ICloudUser } from "../../types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserTie } from "@fortawesome/free-solid-svg-icons"
import { callCloudApiV2 } from "../../utils/cloudApiV2"
import { UserAvatar } from "../../components/commons-ts/userWidget"

export const AccountSettings: FC = () => {
  const [defaultMethod, setDefaultMethod] = useState<MethodTypeEN1177>(
    loadLocalStorageDefaultSiteMethodType(),
  )
  const { currentUser, externallySetCurrentUser } = useSyncronizationContext()

  const [username, setUsername] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")

  useEffect(() => {
    if (currentUser === null) {
      return
    }
    setUsername(currentUser.Username)
    setFirstName(currentUser.FirstName)
    setLastName(currentUser.LastName)
  }, [currentUser])

  const handleUserUpdateCB = useCallback(async (user: ICloudUser) => {
    console.log(`AccountSettings: handleUserUpdateCB`, user)
    callCloudApiV2<ICloudUser>(`/user?user_uuid=${user.UUID}`, {
      method: "PATCH",
      body: JSON.stringify(user),
    })
      .then(({ resp, entity: updatedUser }) => {
        if (!resp.ok) {
          console.error(
            `AccountSettings: handleUserUpdateCB: error updating user: resp NOK: ${resp.status}`,
          )
          return
        }
        if (updatedUser === null) {
          console.error(
            `AccountSettings: handleUserUpdateCB: error updating user: updatedUser is null`,
          )
          return
        }
        console.log(
          `AccountSettings: handleUserUpdateCB: successfully updated user: ${updatedUser.UUID}`,
        )
      })
      .catch((e: any) => {
        console.error(`AccountSettings: handleUserUpdateCB: error updating user: ${e}`)
      })
  }, [])

  const onUserPictureChangedCB = useCallback(
    (pictureUUID: string | null) => {
      if (currentUser === null) {
        return
      }
      let _newCurrentUser = {
        ...currentUser,
        PictureUUID: pictureUUID,
      } // destroy to ensure identity change
      externallySetCurrentUser(_newCurrentUser)
      handleUserUpdateCB(_newCurrentUser)
    },
    [currentUser],
  )

  const onUserSaveCB = useCallback(() => {
    if (currentUser === null) {
      return
    }
    let _newCurrentUser = {
      ...currentUser,
      FirstName: firstName,
      LastName: lastName,
    } // destroy to ensure identity change
    externallySetCurrentUser(_newCurrentUser)
    handleUserUpdateCB(_newCurrentUser)
  }, [currentUser, firstName, lastName])

  return (
    <FlexCol
      style={{
        width: "100%",
        // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
        margin: "auto",
        gap: 30,
        marginBottom: "2rem",
      }}
    >
      {/* Header */}
      <FlexRow
        style={{
          justifyContent: "space-between",
        }}
      >
        <UnderlinedSectionTitle>
          <Translated keyEn="User account" />
        </UnderlinedSectionTitle>
        <FlexRow style={{ gap: 2 }}>
          <Tooltip overlay="Admin" placement="top">
            <Avatar
              size="large"
              style={{
                backgroundColor: COLOR_BG_ORANGE,
              }}
              icon={<FontAwesomeIcon icon={faUserTie} />}
            />
          </Tooltip>
          <UserAvatar user={currentUser} />
        </FlexRow>
      </FlexRow>
      <Row gutter={[10, 10]} align="top" justify="center">
        {/* Avatar */}
        <Col xs={6}>
          {/* User picture */}
          <FlexCol
            style={{
              gap: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Label>
              <Translated keyEn="Avatar" />
            </Label>
            <div>
              <UploadUserImage
                pictureUUID={currentUser?.PictureUUID ?? null}
                setPictureUUID={onUserPictureChangedCB}
              />
            </div>
          </FlexCol>
        </Col>
        {/* Fields */}
        <Col xs={24} md={18}>
          <Row gutter={[10, 10]}>
            <Col xs={24}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn="Username" />
                </Label>
                <Input value={username} size="large" variant="filled" />
              </FlexCol>
            </Col>
            <Col xs={24} md={12}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn="First name" />
                </Label>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  size="large"
                  variant="filled"
                />
              </FlexCol>
            </Col>
            <Col xs={24} md={12}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn="Last name" />
                </Label>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  size="large"
                  variant="filled"
                />
              </FlexCol>
            </Col>
            <Col xs={24}>
              <FlexCol
                style={{
                  gap: 0,
                  alignItems: "start",
                  width: "100%",
                }}
              >
                <Label>
                  <Translated keyEn="Default method" />
                </Label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={defaultMethod}
                  options={[
                    {
                      label: <Translated keyEn="Method 1: Critical Fall Height" />,
                      value: MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION,
                    },
                    {
                      label: <Translated keyEn="Method 2: Adequacy" />,
                      value: MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE,
                    },
                  ]}
                  onChange={(value) => {
                    if (value) {
                      setDefaultMethod(value)
                      saveLocalStorageDefaultSiteMethodType(value)
                    }
                  }}
                />
              </FlexCol>
            </Col>
            {/* Buttons */}
            <Col xs={24} md={12}>
              {/* Save */}
              <Button
                block
                type="primary"
                size="large"
                onClick={onUserSaveCB}
                icon={<SaveOutlined />}
              >
                <Translated keyEn="Save" />
              </Button>
            </Col>
            <Col xs={24} md={12}>
              {/* Sign out */}
              <Button block type="primary" size="large" href="/api/logout" danger>
                <Translated keyEn="Sign out" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </FlexCol>
  )
}
