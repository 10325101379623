import { DoubleRightOutlined } from "@ant-design/icons"
import { Tooltip, Tag, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { EResultTagAdequacy } from "../../../components/commons-ts/common"
import { Equipment, UUID } from "../../../generated/proto-ts/main"
import { useUsercommSiteChildrenRecursiveBLE } from "../../../usercomm/local/ble/usercommAsyncRequestBLE"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import { getEquipmentResultPFAdq } from "../../../calculus/calculus_PF"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { useUsercommSiteChildrenRecursiveBimodal } from "../../../usercomm/common/usercommAsyncRequestBimodal"

export const SiteEquipmentsTablePF_CFH: FC<{
  siteUUID: UUID | null
  equipments: Equipment[] | null
}> = ({ siteUUID, equipments }) => {
  const [_, equipmentZonesMap, zoneImpactsMap, getEntitiesRecursive] =
    useUsercommSiteChildrenRecursiveBimodal()

  useEffect(() => {
    if (siteUUID === null) {
      return
    }
    getEntitiesRecursive(siteUUID)
  }, [siteUUID, equipments])

  const columns = useMemo((): ColumnsType<Equipment> => {
    return [
      {
        title: "📷", // Photo
        render: (_, equipment) => {
          let pictureUUID = equipment.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}>
              <TableImageThumbnail pictureUUID={pictureUUID} alt={equipment.equipment_name} />
            </Link>
          )
        },
      },
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        ellipsis: true,
        render: (_, equipment) => {
          return (
            <Link
              to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {equipment.equipment_name}
            </Link>
          )
        },
      },
      {
        title: <Translated keyEn="# Zones" />,
        render: (_, equipment) => {
          if (equipmentZonesMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
          if (equipmentZones == undefined) {
            return <span>N/A</span>
          }
          if (!equipmentZones) {
            return <span>N/A</span>
          }
          let nbZones = 0
          for (let zone of equipmentZones) {
            if (zone.deleted_at === 0) {
              nbZones++
            }
          }
          return <span>{nbZones}</span>
        },
      },
      {
        key: "result",
        title: (
          <Tooltip overlay={<Translated keyEn="Result" />}>
            <Translated keyEn="Result" />
          </Tooltip>
        ),
        render: (_, equipment) => {
          if (equipmentZonesMap === null || zoneImpactsMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
          if (equipmentZones === undefined) {
            return <Tag>N/A</Tag>
          }
          let result = getEquipmentResultPFAdq(equipmentZones, zoneImpactsMap)
          if (result === null) {
            return <Tag>N/A</Tag>
          }
          return <EResultTagAdequacy result={result.result} />
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, equipment) => {
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}>
              <Button type="link" size="small">
                <DoubleRightOutlined />
              </Button>
            </Link>
          )
        },
      },
    ]
  }, [equipments, equipmentZonesMap, zoneImpactsMap])

  return (
    <Table
      dataSource={equipments ?? []}
      loading={equipments === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      onRow={(equipment) => {
        return {
          style: {
            backgroundColor: equipment.deleted_at !== 0 ? COLOR_BG_ROW_DELETED : undefined,
          },
        }
      }}
      footer={() => (
        <Translated keyEn="Equipments table for EN 1177 - PLAYGROUND FLOORS (Method 1: Critical Fall Height)" />
      )}
    />
  )
}

export const SiteEquipmentsTablePF_ADQ: FC<{
  siteUUID: UUID | null
  equipments: Equipment[] | null
}> = ({ siteUUID, equipments }) => {
  const [_, equipmentZonesMap, zoneImpactsMap, getEntitiesRecursive] =
    useUsercommSiteChildrenRecursiveBimodal()

  useEffect(() => {
    if (siteUUID === null) {
      return
    }
    getEntitiesRecursive(siteUUID)
  }, [siteUUID, equipments])

  const columns = useMemo((): ColumnsType<Equipment> => {
    return [
      {
        key: "equipment",
        title: <Translated keyEn="Equipment" />,
        ellipsis: true,
        render: (_, equipment) => {
          return (
            <Link
              to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {equipment.equipment_name}
            </Link>
          )
        },
      },
      {
        title: <Translated keyEn="# Zones" />,
        render: (_, equipment) => {
          if (equipmentZonesMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
          if (equipmentZones == undefined) {
            return <span>N/A</span>
          }
          if (!equipmentZones) {
            return <span>N/A</span>
          }
          let nbZones = 0
          for (let zone of equipmentZones) {
            if (zone.deleted_at === 0) {
              nbZones++
            }
          }
          return <span>{nbZones}</span>
        },
      },
      {
        key: "result",
        title: (
          <Tooltip overlay={<Translated keyEn="Result" />}>
            <Translated keyEn="Result" />
          </Tooltip>
        ),
        render: (_, equipment) => {
          if (equipmentZonesMap === null || zoneImpactsMap === null) {
            return null
          }
          let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
          let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
          if (equipmentZones === undefined) {
            return <Tag>N/A</Tag>
          }
          let result = getEquipmentResultPFAdq(equipmentZones, zoneImpactsMap)
          if (result === null) {
            return <Tag>N/A</Tag>
          }
          return <EResultTagAdequacy result={result.result} />
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, equipment) => {
          return (
            <Link to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}>
              <Button type="link" size="small">
                <DoubleRightOutlined />
              </Button>
            </Link>
          )
        },
      },
    ]
  }, [equipments, equipmentZonesMap, zoneImpactsMap])

  return (
    <Table
      dataSource={equipments ?? []}
      loading={equipments === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      onRow={(equipment) => {
        return {
          style: {
            backgroundColor: equipment.deleted_at !== 0 ? COLOR_BG_ROW_DELETED : undefined,
          },
        }
      }}
      footer={() => (
        <Translated keyEn="Equipments table for EN 1177 - PLAYGROUND FLOORS (Method 2: Adequacy)" />
      )}
    />
  )
}
