import { FC, useEffect, useMemo, useState } from "react"
import { Translated } from "../utils/translated"
import { Tabs, TabsProps } from "antd"
import { BugOutlined, FilePdfOutlined, ReloadOutlined, UserOutlined } from "@ant-design/icons"
import { FlexCol } from "../components/commons-ts/common"
import { AccountSettings } from "./Settings/AccountSettings"
import { useHistory, useLocation } from "react-router-dom"
import { useSyncronizationContext } from "../providers/syncronizationProvider"
import { ECloudRole } from "../types"
import { UpdatesPage } from "./UpdatesPage"
import { DebugPage } from "./DebugPage"

enum ETabKey {
  Account = "account",
  Updates = "updates",
  Debug = "debug",
}

export const SettingsGeneral: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const [selectedTabKey, setSelectedTabKey] = useState<ETabKey>(ETabKey.Account)
  const { isAdmin } = useSyncronizationContext()

  useEffect(() => {
    let hash = location.hash
    if (!hash) {
      return
    }
    hash = hash.replace("#", "")
    switch (hash) {
      case ETabKey.Account:
        setSelectedTabKey(ETabKey.Account)
        break
      case ETabKey.Updates:
        setSelectedTabKey(ETabKey.Updates)
        break
      case ETabKey.Debug:
        setSelectedTabKey(ETabKey.Debug)
        break
    }
  }, [])

  const memoTabItems = useMemo(() => {
    let items: TabsProps["items"] = [
      {
        key: ETabKey.Account,
        label: (
          <span
            style={{
              marginLeft: 5,
            }}
          >
            <Translated keyEn="Account" />
          </span>
        ),
        icon: <UserOutlined />,
        children: <AccountSettings />,
      },
      {
        key: ETabKey.Updates,
        label: (
          <span
            style={{
              marginLeft: 5,
            }}
          >
            <Translated keyEn="Updates" />
          </span>
        ),
        icon: <ReloadOutlined />,
        children: <UpdatesPage />,
      },
      {
        key: ETabKey.Debug,
        label: (
          <span
            style={{
              marginLeft: 5,
            }}
          >
            <Translated keyEn="Debug" />
          </span>
        ),
        icon: <BugOutlined />,
        children: <DebugPage />,
      },
    ]
    return items
  }, [isAdmin])

  return (
    <FlexCol
      style={{
        width: "80vw",
        maxWidth: "55rem", // MAX_WIDTH_CENTRAL_CONTAINER,
        margin: "auto",
        gap: 30,
        marginBottom: "2rem",
        alignItems: "center",
      }}
    >
      <Tabs
        style={{
          width: "100%",
        }}
        destroyInactiveTabPane
        // centered
        animated
        tabBarGutter={20}
        activeKey={selectedTabKey}
        onChange={(key: string) => {
          setSelectedTabKey(key as ETabKey)
          // set the hash
          history.push(`#${key}`)
        }}
        size="large"
        items={memoTabItems}
      ></Tabs>
    </FlexCol>
  )
}
